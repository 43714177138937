import debounce from 'lodash/debounce'
import useCookieService from '~/utils/useCookieService'
import { useSyncStore } from '~/stores/sync'

export default function () {
  const cookieService = useCookieService()
  const syncStore = useSyncStore()
  window.cookieService = cookieService
  const isBot = ref(true)
  const init = async () => {
    isBot.value = await cookieService.isBot()
    if (isBot.value) {
      syncStore.setBot(isBot.value)
      return true
    }
    await cookieService.getCookies()
    return useAuthStore().readCookies()
  }
  const sync = debounce(() => {
    if (isBot.value) {
      syncStore.setBot(isBot.value)
      return true
    }
    return cookieService.setCookies()
  }, 100)
  const syncWithDelay = debounce(() => {
    if (isBot.value) {
      syncStore.setBot(isBot.value)
      return true
    }
    return cookieService.setCookies()
  }, 1500)
  return {
    init,
    sync,
    syncWithDelay
  }
}
