export default () => {
  const { authDomain } = useRuntimeConfig().public
  const BASE_URL = `${authDomain}/cookie-service/`
  const syncStore = useSyncStore()

  const sendRequest = (url, options) =>
    $fetch(url, {
      credentials: 'include',
      ...options
    })

  const processSso = () => {
    navigateTo(`${BASE_URL}sso?redirectUrl=${window.location.href}`, {
      external: true,
      replace: true
    })
  }

  const healthCheck = () => sendRequest('/cookie-service/health-check')
  const isBot = async () => {
    const { bot } = await sendRequest('/cookie-service/is-bot')
    return bot
  }
  const checkThirdPartyCookies = async () => {
    let res = await sendRequest(`${BASE_URL}check-third-party-cookies`)
    if (res?.thirdPartyCookiesAllowed) {
      return true
    }
    res = await sendRequest(`${BASE_URL}check-third-party-cookies`)
    return !!res?.thirdPartyCookiesAllowed
  }
  const getCookies = async () => {
    try {
      const response = await sendRequest('/cookie-service/sync')
      syncStore.setSynced(true)
      return response
    } catch (e) {
      if (e?.response?.status === 403) {
        return processSso()
      }
    }
  }
  const setCookies = () =>
    sendRequest('/cookie-service/sync', { method: 'post' })
  return {
    // checkIbisSSOToken,
    healthCheck,
    isBot,
    checkThirdPartyCookies,
    getCookies,
    setCookies
  }
}
