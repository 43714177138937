import { storeToRefs } from 'pinia'
// import debounce from 'lodash/debounce'
import { useAuthStore } from '~/stores/auth'
import { useCartStore } from '~/stores/cart'
import { GA_CLIENT_ID_KEY, GA_SESSION_ID_KEY } from '~/utils/constants'

export default function () {
  if (import.meta.server || process.server) {
    return false
  }
  const authStore = useAuthStore()
  const cartStore = useCartStore()

  const storeType = useStoreType()
  const { token, tokenStore } = storeToRefs(authStore)
  const cartToken = computed(() => cartStore.cartToken)

  const setGaCookies = () => {
    const gaSessionIdCookie = useCookie(GA_SESSION_ID_KEY)
    const gaClientIdCookie = useCookie(GA_CLIENT_ID_KEY)

    const prefix = storeType.isHunting ? 'w_' : 'g_'
    const gaCookiePattern = /_ga_[A-Z0-9]{10}/
    const getId = (cookieValue, isClientId) => {
      const splitString = cookieValue?.split('.')
      if (splitString && splitString.length > 3) {
        return isClientId
          ? `${prefix}${splitString[2]}.${splitString[3]}`
          : `${prefix}${splitString[2]}`
      }
      return ''
    }

    const isValidCookie = cookieValue =>
      cookieValue && !cookieValue.includes(prefix)

    if (
      isValidCookie(gaSessionIdCookie.value) ||
      isValidCookie(gaClientIdCookie.value)
    ) {
      return false
    }
    const cookies = getAllCookies()

    const sessionIdKey = Object.keys(cookies)?.find(cookieKey =>
      gaCookiePattern.test(cookieKey)
    )
    const clientIdKey = '_ga'

    if (cookies[sessionIdKey] && cookies[clientIdKey]) {
      gaSessionIdCookie.value = getId(cookies[sessionIdKey], false)
      gaClientIdCookie.value = getId(cookies[clientIdKey], true)
      sync()
    }
  }
  const { sync, syncWithDelay, init } = useCookieSync()
  init().then(() => {
    checkCookiesExist('_ga', setGaCookies, 20000, 1000)

    watch(token, syncWithDelay)
    watch(tokenStore, sync)
    watch(cartToken, () => {
      if (!authStore.loggedIn) {
        sync()
      }
    })
  })
}

const getAllCookies = () => {
  const parseCookies = cookieString => {
    return Object.fromEntries(
      cookieString.split('; ').map(cookie => {
        const [name, ...rest] = cookie.split('=')
        return [name, rest.join('=')]
      })
    )
  }

  if (process.client) {
    return parseCookies(document.cookie)
  }

  if (process.server) {
    const headers = useRequestHeaders()
    const cookieHeader = headers.cookie || ''
    return parseCookies(cookieHeader)
  }

  return {}
}

const checkCookiesExist = (
  cookieName,
  callback,
  timeout = 5000,
  interval = 1000
) => {
  const start = Date.now()

  const intervalId = setInterval(() => {
    const cookie = useCookie(cookieName).value
    if (cookie) {
      callback()
      clearInterval(intervalId)
    } else if (Date.now() - start >= timeout) {
      console.error('Timeout waiting for cookie')
      callback()
      clearInterval(intervalId)
    }
  }, interval)
}
